<template>
  <div class="pg">
       <Top :topIndex="3"  @changeL="changeL"></Top>
       <div style="min-height:10vh">
            <img :src="cn_enobj.site.index.home_logo"  class="zhanweiImg" alt="">
       </div>
       <!-- 新闻中心 -->
        <div class="labelsTop" style="padding: 5vh 0;" >
            <div style="display: flex;"   class="reveal-r" >
                <div style="text-align: center;">
                     <div class="labels">{{cn_enobj.site.newCenter.press.title||''}}</div>
                    <div v-show="locale == 'zh'" class="labels-en">{{cn_enobj.site.newCenter.press.subtitle||''}}</div>
                </div>
            </div>
        </div>
        <div class="table-btn">
            <div v-for="(i,index) in labelList" :key='index'
            @click="cahngeLabel(i,index)" 
            :style="{'border-bottom':labelIndex === index?'2px solid #1CAE1B':'',color:labelIndex === index?'#1CAE1B':''}"
            >{{locale === 'zh'?i.label:i.en_label}}</div>
        </div>
        <div class="newCenter-item3 ">
            <div class="newCenter-item3-box">
                <div class="newCenter-item3-card reveal-bottom" v-for="(i,index) of list" :key="index"  @click="goDetaile(i)">
                    <div class="card-text">
                        <div class="wenanLable">{{locale == 'zh'?i.title:i.en_title}}</div>
                        <div class="wenanBody" style="margin-bottom: 2vh;"> {{locale == 'zh'?i.introduce:i.en_introduce}}</div>
                        <div class="timeText">{{i.publish_time}}</div>
                    </div>
                </div>
            </div>
            <div class="loadMore">
                <div class="loadMore-btn reveal-r"  v-if="labelList.length>8"  @click="loadMoreEV()">{{bottom}}</div>
            </div>
        </div>
        <Bottom></Bottom>
        <ReturnTop></ReturnTop>
    </div>
</template>

<script>
import Top from '@/components/top.vue'
import Bottom from '@/components/bottom.vue'
import ReturnTop from '@/components/returnTop.vue'
import scrollReveal from 'scrollreveal'
export default {  
    components:{Top,Bottom,ReturnTop},
    data(){
        return{
            scrollReveal: scrollReveal(),
            labelIndex:0,
            labelList:[],
            labelObj:{},
            list:[],  // 显示
            listObj : { // 投资理念对象
                imgs:'img/01.jpg',
                times:'2021.08.28',
                wenantitle:'某某某某文案1',
                body:'人生就像一座山，重要的不是它的高低，而在于灵秀',
            },
            listIndex:0,//投资理念下标
            locale:'',
            realTimeClData:'', // 定时器
            realTimeClTime:4000, // shijian
            isDisable:false, // 没有数据时禁止加载更多请求
            page:1,
            cn_enobj:{},
            bottom:''
        }
    },// vue的两个生命钩子，这里不多解释。
   
     //项目中执行，自定义类名。
    mounted() {
        this.scrollReveal.reveal('.reveal-bottom', {origin: 'bottom',...this.global});
        this.scrollReveal.reveal('.reveal-r', {...this.global,distance: '0px', scale: 0.9});
    },
     created(){
        this.getLabelData()
        this.changeL(this.$i18n.locale)
    },
   
    methods:{
        // 切换导航
        cahngeLabel(i,index){
            this.labelIndex = index
            this.labelObj = i
            this.page = 1
            this.list = []
            this.getPressData('page')
            
        },
        changeL(str){
            this.locale = str
            let cnobj = JSON.parse(sessionStorage.getItem("cnobj"))
            let enobj = JSON.parse(sessionStorage.getItem("enobj"))

            this.cn_enobj = str == 'zh' ?cnobj:enobj
            this.bottom = str == 'zh' ?cnobj.site.components.bottom.more_bottom:enobj.site.components.bottom.more_bottom
        },
        // 获取新闻中心列表数据
        getPressData(){
            this.$axios({
                method:'get',
                url:'/api/content/list/press?page='+this.page+'&label_id='+this.labelObj.id,
            }).then(res=>{
                if(this.page !== 1&&res.data.data.data.length === 0 ){
                    this.$message({
                        message: '没有更多数据了！',
                        type: 'warning'
                    });
                    this.isDisable = true
                    return
                }
                this.isDisable = false
                this.list = this.list.concat(res.data.data.data)
            })  
        },
        // 获取昊辰新闻列表数据
        getLabelData(){
            this.$axios({
                method:'get',
                url:'/api/content/label',
            }).then(res=>{
                this.labelList = res.data.data
                this.labelObj = res.data.data[0]
                this.page = 1
                this.list = []
                this.getPressData()
            })  
        },
        loadMoreEV(){
            if(this.isDisable)return
            this.page = this.page+1
            this.getPressData()
        },
        goDetaile(i){
            this.$router.push({path:'/inCaseDetaile', query:{type:'press',id: i.id}})
        }
    }

}
</script>

<style lang="less" scoped>
@import "../../src/assets/css/pc/newCenter_pc.less"; 
</style>